import React, { useState } from "react"
import {
  Box,
  HStack,
  IconButton,
  Input,
  List,
  ListItem,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react"
import { DeleteIcon } from "@chakra-ui/icons"

const FileUpload = ({files, setFiles}) => {
  const toast = useToast()

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files)
    setFiles((prevFiles) => [...prevFiles, ...newFiles])
    // setFiles(event.target.files[0])
  }

  const handleRemoveFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName))
  }

  return (
    <HStack align="flex-start" p={4} spacing={6} w="95%">
      <VStack align="stretch" w="100%">
        <Box borderWidth={1} borderRadius="lg" p={4}>
          <Text fontWeight="bold" mb={4}>
            Files
          </Text>
          <Box
            w="100%"
            h="150px"
            borderWidth={2}
            borderRadius="md"
            borderColor="gray.200"
            borderStyle="dashed"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault()
              const newFiles = Array.from(e.dataTransfer.files)
              setFiles((prevFiles) => [...prevFiles, ...newFiles])
            }}
          >
            <Text>Drag & drop files here, or click to select files</Text>
            <Input
              type="file"
              multiple
              onChange={handleFileChange}
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
              opacity="0"
              cursor="pointer"
            />
          </Box>

          {files.length > 0 && (
            <Box mt={4}>
              <Text fontWeight="bold" mb={2}>
                Already Included Files
              </Text>
              <List spacing={2}>
                {files.map((file) => (
                  <ListItem
                    key={file.name}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <HStack>
                      <Text>{file.name}</Text>
                      <Text>({(file.size / 1024 / 1024).toFixed(2)} MB)</Text>
                    </HStack>
                    <IconButton
                      icon={<DeleteIcon />}
                      size="sm"
                      onClick={() => handleRemoveFile(file.name)}
                      aria-label="Remove file"
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </Box>
      </VStack>
    </HStack>
  )
}

export default FileUpload
