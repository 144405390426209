import React from 'react';
import { Box, Center, Text, Button } from '@chakra-ui/react';

const Cancel = () => {
  return (
    <Box
    height="100vh"
    display="flex"
    alignItems="center"
    justifyContent="center"
    bg="gray.100"
    p={4}
  >
    <Box
      width="70%"
      maxWidth="800px"
      p={8}
      bg="white"
      boxShadow="md"
      borderRadius="md"
      textAlign="center"
    >
      <Text fontSize="2xl" mb={4} color="red.500">
        Payment Cancelled
      </Text>
      <Text fontSize="lg" mb={6}>
        We're sorry, but your payment could not be processed. Please try again or contact support if the problem persists.
      </Text>
      
    </Box>
  </Box>
  )
}

export default Cancel
