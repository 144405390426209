import React, { useState } from "react"
import {
  Box,
  Button,
  HStack,
  IconButton,
  List,
  ListItem,
  Text,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react"
import { DeleteIcon } from "@chakra-ui/icons"
import useApiCallHook from "../Helpers/useApiCallHook"

const TextInputComponent = ({ onSave, textInput, setTextInput }) => {

  const handleTextInputChange = (event) => {
    setTextInput(event.target.value)
  }

 

  return (
    <HStack align="flex-start" p={4} spacing={6} w="95%">
      <VStack align="stretch" w="100%">
        <Box borderWidth={1} borderRadius="lg" p={4}>
          <Text fontWeight="bold" mb={4}>
            Prompt
          </Text>
          <Textarea
            value={textInput}
            onChange={handleTextInputChange}
            placeholder="Enter your text here..."
            size="xl"
            height="200px"
            style={{
              padding: " 15px",
            }}
            rounded="inherit"
          />
          {/* <Button mt={4} colorScheme="blue" onClick={handleSaveText}>
            Save Text
          </Button> */}
          {/* {texts.length > 0 && (
            <Box mt={4}>
              <List spacing={2}>
                {texts.map((text, index) => (
                  <ListItem
                    key={index}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Text>{text}</Text>
                    <IconButton
                      icon={<DeleteIcon />}
                      size="sm"
                      onClick={() => handleRemoveText(index)}
                      aria-label="Remove text"
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )} */}
          <Box textAlign="center" mt={4}>
            {/* <Text>{textInput.length} characters</Text> */}
            <Text>{textInput?.length ? textInput?.length : 0} characters</Text>

          </Box>
        </Box>
      </VStack>
    </HStack>
  )
}

export default TextInputComponent
