import { ViewOffIcon } from "@chakra-ui/icons";
import SubscriptionReport from "./Components/SubscriptionReport";
import User from "./Components/User";
import { useEffect, useState } from "react";
import useApiCallHook from "./Helpers/useApiCallHook";
import Analytics from "./Components/Analytics";
import DashBoardBox from "./Components/DashBoardBox";
import { useNavigate } from "react-router-dom";
import Sources from "./Components/Sources";

function App() {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const { getDashboardStats } = useApiCallHook();
  const [dashboardStats, setDashboardStats] = useState();
  const isMobile = window.innerWidth < 768;
  const tabNames = ["Users", "Payment History", "Traning"];

  const tabIndex = {
    0: <User />,
    // 1: <Analytics />,
    1: <SubscriptionReport />,
    2: <Sources />
  };

  const fetchAndSetDashboardStats = async () => {
    const response = await getDashboardStats();
    if (response) {
      setDashboardStats({
        "Days Since Launch": response?.daySinceLaunch,
        "Total Users": response?.totalUsers,
        "Total Active Users": response?.totalActiveUsers,
        "Total Reminders Completed": response?.totalReminderCompleted,
        "Avg. Reminders per Active User": response?.avgReminderPerActiveUser,
        "Avg. Reminders per Active User per Day":
          response?.avgReminderPerActiveUserPerDay,
      });
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
    fetchAndSetDashboardStats();
  }, []);

  return (
    <div style={{ maxHeight: "100vh", minHeight: "100vh", width: "100vw" }}>
      {isMobile ? (
        <div
          style={{
            maxHeight: "100vh",
            minHeight: "100vh",
            width: "100vw",
            placeContent: "center",
            display: "grid",
          }}
        >
          <div style={{ color: "red", fontSize: "16px", margin: "auto" }}>
            <ViewOffIcon color="red" marginRight="8px" /> Mobile view is not
            supported{" "}
          </div>
        </div>
      ) : (
        <div
          style={{
            minHeight: "100vh",
            width: "100vw",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <h1
              style={{
                color: "#333",
                fontSize: "24px",
                fontWeight: "bold",
              }}
            >
              Marissa-Chatbot
            </h1>
            <button
              style={{
                padding: "8px 20px",
                border: "1px solid gray",
                borderRadius: "8px",
              }}
              onClick={() => {
                localStorage.removeItem("token")
                navigate("/")
              }}
            >
              Logout User
            </button>
          </div>
          <hr
            style={{
              height: "2px",
              width: "100%",
              // marginBottom: "px",
              color: "darkgray",
            }}
          />

          {/* <div
            style={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              padding: "20px 20px 20px 0",
              marginBottom: "20px",
            }}
          >
            {dashboardStats ? (
              Object.entries(dashboardStats).map(([key, value]) => {
                return (
                  <DashBoardBox
                    heading={key}
                    value={value}
                    height={"100px"}
                    width={"140px"}
                  />
                )
              })
            ) : (
              <h2>Stats Loading...</h2>
            )}
          </div> */}

          <div
            style={{
              minWidth: "100%",
              maxWidth: "80vw",
              border: "1px solid darkgray",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                padding: "12px",
                display: "flex",
              }}
            >
              {tabNames.map((tab, i) => {
                return (
                  <button
                    key={tab}
                    style={{
                      fontSize: "18px",
                      fontWeight: index === i ? "600" : "400",
                      minWidth: "120px",
                      padding: "12px 8px",
                      borderRadius: "8px",
                      border: "1px solid darkgray",
                      background: "white",
                      margin: "0 12px",
                    }}
                    onClick={() => setIndex(i)}
                  >
                    {tab}
                  </button>
                )
              })}
            </div>

            <div
              style={{
                flex: "1",
                width: "100%",
                overflow: "auto",
              }}
            >
              <div style={{ padding: "8px" }}>{tabIndex[index]}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default App;
