// PaymentSuccessPage.js
import React from 'react';
import { Box, Center, Heading, Text, Button, VStack } from '@chakra-ui/react';

const Successfull = () => {
  return (
    <Center height="100vh" width="100vw" bg="gray.100">
    <Box
      width={{ base: "90%", md: "70%" }}
      p={6}
      bg="white"
      boxShadow="md"
      borderRadius="md"
      textAlign="center"
    >
      <Heading mb={4} size="lg" color="green.500">
        Payment Successful
      </Heading>
      <Text mb={6} fontSize="lg" color="gray.600">
        Your payment has been processed successfully. Thank you for your purchase!
      </Text>
      <VStack spacing={4}>
       
      </VStack>
    </Box>
  </Center>
  )
}

export default Successfull
