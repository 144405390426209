import React from "react"
import { Box, Button, Divider, Text, VStack, useToast } from "@chakra-ui/react"
import useApiCallHook from "../Helpers/useApiCallHook"

const SourcesInfoText = ({ onSave, textInput, setTextInput }) => {
  const toast = useToast()
  const { saveText,getPrompt } = useApiCallHook()

  const handleSaveText = async () => {
    const response = await getPrompt()
    // if(getPrompt){
    //   setTextInput("hello")
    // }
    try {
      console.log("testing")

      await saveText({ textData: textInput })
      setTextInput("")

      toast({
        title: "Text saved",
        description: "Your text has been saved successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      })
      //  if (onSave) {
      //    onSave()
      //  }
    } catch (error) {
      toast({
        title: "Error",
        description: "There was an error saving your text.",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
    }
  }
  return (
    <VStack align="stretch" w="30%" marginTop={"55px"}>
      <Box borderWidth={1} borderRadius="lg" p={4}>
        <Text fontWeight="bold">Prompt</Text>
        <Divider my={2} />
        <Text>
          Input allows you to set specific prompts for bots, ensuring they
          operate as intended. By configuring the prompt, the bot's responses
          align perfectly with user requirements, enhancing functionality.
        </Text>
        <Button colorScheme="blue" mt={4} onClick={handleSaveText}>
          Save
        </Button>
      </Box>
    </VStack>
  )
}

export default SourcesInfoText
