import axios from "axios";

const useApiCallHook = () => {
  const getFileUploaded = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_API_END_POINT}/admin/get-files`
      );

      return { response };
    } catch (err) {
      return err;
    }
  };
  const getPrompt = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_API_END_POINT}/admin/get-prompt`
      );

      return { response };
    } catch (err) {
      return err;
    }
  };
  const uploadFile = async (data, files) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_API_END_POINT}/admin/upload`,
        data,
        {
          headers: {
            fileLength: files.length,
          },
        }
      );

      return { response };
    } catch (err) {
      return err;
    }
  };

  const saveText = async (textData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_API_END_POINT}/save-text`,
        textData
      );
      return response.data;
    } catch (error) {
      console.error("Error saving text:", error);
      throw error;
    }
  };

  const getUser = async (dates) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_API_END_POINT}/admin/users?${
          dates ? dates : ""
        }`
      );
      const data = await response.json();
      return { data };
    } catch (err) {
      return err;
    }
  };

  const getSubscriptionReport = async (number) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_API_END_POINT}/admin/subscription-report/${number}`
      );
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  };

  const getWhatsappLogs = async (number) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_API_END_POINT}/admin/whatsapp-logs/${number}`
      );
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  };

  const getPaymentHistory = async (dates) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_API_END_POINT}/admin/payment-history?${
          dates ? dates : ""
        }`
      );
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  };

  const getReminderHistory = async (number) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_API_END_POINT}/admin/reminder-history/${number}`
      );
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  };

  const getDashboardStats = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_API_END_POINT}/admin/dashboard`
      );
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  };

  const getAnalyticsData = async (dates) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_API_END_POINT}/admin/analytics?${
          dates ? dates : ""
        }`
      );
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  };

  const getLoginToken = async (body) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_SERVER_API_END_POINT + "/admin/login",
        {
          headers: {
            "Content-Type": "application/json",
            "sec-fetch-dest": "empty",
            "sec-fetch-mode": "cors",
            "sec-fetch-site": "cross-site",
          },
          body: JSON.stringify(body),
          method: "POST",
        }
      );

      const data = await response.json();

      return data;
    } catch (err) {
      return err;
    }
  };
  const getUserDashboardData = async (dates) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_API_END_POINT}/admin/user-dashboard?${
          dates ? dates : ""
        }`
      );
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  };

  const getPaymentDashboardData = async (dates) => {
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_SERVER_API_END_POINT
        }/admin/payment-dashboard?${dates ? dates : ""}`
      );
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  };

  const getUserLocations = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_SERVER_API_END_POINT + "/admin/user-location"
      );
      const data = await response.json();
      return data;
    } catch (err) {
      return err;
    }
  };

  https: return {
    getUser,
    getSubscriptionReport,
    getWhatsappLogs,
    getPaymentHistory,
    getReminderHistory,
    getDashboardStats,
    getAnalyticsData,
    uploadFile,
    saveText,
    getLoginToken,
    getUserDashboardData,
    getPaymentDashboardData,
    getUserLocations,
    getPrompt,
    getFileUploaded,
  };
};

export default useApiCallHook;
