import React from "react"
import { Box, Button, Divider, Text, VStack } from "@chakra-ui/react"

const SourcesInfo = ({ handleUpload }) => {
  
  return (
    <VStack align="stretch" w="30%" marginTop={"55px"}>
      <Box borderWidth={1} borderRadius="lg" p={4}>
        <Text fontWeight="bold">Upload Files</Text>
        <Divider my={2} />
       <Text> Simply drag and drop your file or click the Retrain Chatbot to get started. Our advanced bot will analyze the content of your file and quickly adapt to provide accurate and relevant responses.</Text>
        <Button colorScheme="blue" mt={4} onClick={handleUpload}>
          Retrain Chatbot
        </Button>
      </Box>
    </VStack>
  )
}

export default SourcesInfo
