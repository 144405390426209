import React, { useEffect, useState } from "react"
import useApiCallHook from "../Helpers/useApiCallHook"
import { useNavigate } from "react-router-dom"
import { useToast } from "@chakra-ui/react"
import useToastHook from "../Helpers/useToastHook"

function LoginPage() {
  const navigate = useNavigate()
  const { getLoginToken } = useApiCallHook()
  const { ToastService } = useToastHook()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [usernameError, setUsernameError] = useState("")
  const [passwordError, setPasswordError] = useState("")

  const handleUsernameChange = (event) => {
    setUsername(event.target.value)
    if (!event.target.value) {
      setUsernameError("Username is required")
    } else {
      setUsernameError("")
    }
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
    if (!event.target.value) {
      setPasswordError("Password is required")
    } else {
      setPasswordError("")
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!username) {
      setUsernameError("Username is required")
      return
    }
    if (!password) {
      setPasswordError("Password is required")
      return
    }
    ToastService.showInfoToast({
      title: "Login",
      message: "Logging in...",
    })
    try {
      const data = await getLoginToken({ username, password })

      if (data.token) {
        localStorage.setItem("token", data.token)
        ToastService.showSuccessTaost({
          title: "Login Success",
        })
        navigate("/dashboard")
      } else {
        ToastService.showErrorToast({
          title: "Login Failed",
          message: data.error,
        })
      }
    } catch (error) {
      console.log("errror", error)
      ToastService.showErrorToast({
        title: "Login Failed",
        message: error,
      })
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("token")
    if (token) {
      window.location.href = "/dashboard"
    }
  }, [])

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        padding: "20px",
        backgroundColor: "#f4f4f4",
        borderRadius: "8px",
        height: "100vh",
        width: "100vw",
        margin: "auto",
        display: "grid",
        placeContent: "center",
      }}
    >
      <div style={{ width: "400px" }}>
        <h1
          style={{
            color: "#333",
            fontSize: "24px",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          Login
        </h1>
        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={username}
              onChange={handleUsernameChange}
              placeholder="Username"
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
            {usernameError && (
              <p
                style={{ color: "red", fontSize: "12px", margin: "4px 0 0 0" }}
              >
                {usernameError}
              </p>
            )}
          </div>
          <div style={{ marginBottom: "10px" }}>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Password"
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
            {passwordError && (
              <p
                style={{ color: "red", fontSize: "12px", margin: "4px 0 0 0" }}
              >
                {passwordError}
              </p>
            )}
          </div>
          <button
            type="submit"
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "4px",
              border: "none",
              backgroundColor: "#4CAF50",
              color: "#fff",
              cursor: "pointer",
            }}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}

export default LoginPage
