// Sources.jsx
import React, { useEffect, useState } from "react"
import { Button, Box, VStack, HStack, useToast ,Heading, SimpleGrid,Text,Image} from "@chakra-ui/react"
import TextInputComponent from "./TextInputComponent"
import SourcesInfo from "./SourcesInfo"
import FileUpload from "./FileUpload"
import useApiCallHook from "../Helpers/useApiCallHook"
import SourcesInfoText from "./SourceInfoText"
import imgsrc from '../img/file.png'
const Sources = () => {
  const { uploadFile ,getPrompt,getFileUploaded} = useApiCallHook()
  // const { saveText,getPrompt } = useApiCallHook()

  const [activeComponent, setActiveComponent] = useState("upload")
  const [files, setFiles] = useState([])
  const [textInput, setTextInput] = useState("")
  const [fileUplodaed,setFileUplodaed]=useState(null)

  const toast = useToast()
const getPromptFunc=async()=>{
  const filesUploaded=await getFileUploaded()
  if(filesUploaded?.response?.data){
    setFileUplodaed(filesUploaded?.response?.data?.data)
  }
const data=await getPrompt()
if(data){
console.log("adsdasd",data,filesUploaded)
  setTextInput(data.response.data.textData)
}

}
useEffect(()=>{
  getPromptFunc()
},[])




  const handleUpload = async () => {
    const formData = new FormData()
    files.forEach((file,i) => {
      formData.append(`file${i}`, file)
    })

    let xyz = await uploadFile(formData, files)
    setFiles([])

    toast({
      title: "Files uploaded",
      description: "Your files have been uploaded successfully.",
      status: "success",
      duration: 3000,
      isClosable: true,
    })
  }

  const handleSaveText = () => {}

  return (
    <Box>

    <HStack align="flex-start" p={4} spacing={6}>
      <VStack align="stretch" w="70%">
        <Box>
          <Button
            colorScheme="blue"
            onClick={() => setActiveComponent("upload")}
            mr={2}
          >
            Upload Documents
          </Button>
          <Button colorScheme="blue" onClick={() => setActiveComponent("text")}>
            Prompt
          </Button>
        </Box>
        <Box w="100%">
          {activeComponent === "upload" && (
            <FileUpload files={files} setFiles={setFiles} />
          )}
          {activeComponent === "text" && (
            <TextInputComponent
              onSave={handleSaveText}
              textInput={textInput}
              setTextInput={setTextInput}
            />
          )}
        </Box>
      </VStack>
      {activeComponent === "upload" && (
        <SourcesInfo handleUpload={handleUpload} />
      )}
      {activeComponent === "text" && (
        <SourcesInfoText textInput={textInput} setTextInput={setTextInput} />
      )}
    
  
    </HStack>
      {fileUplodaed &&  <Box p={4}>
     <Heading mb={4} textAlign="center">Uploaded Files</Heading>
     <SimpleGrid columns={{ base: 2, md: 6 }} spacing={4}>
       {fileUplodaed.map((file, index) => (
        //  <Box key={index} p={4} borderWidth="1px" borderRadius="md" textAlign="center">
        //    <Text>{file}</Text>
        //  </Box>
         <Box key={index} p={4} borderWidth="1px" borderRadius="md" textAlign="center">
          <Image src={imgsrc} alt={`file-${index}`} boxSize="100px" objectFit="cover" />
          <Text mt={2}>{file}</Text>
        </Box>
       ))}
     </SimpleGrid>
   </Box>}
    </Box>
  )
}

export default Sources
